import React from 'react';
import css from './pageTitle.module.scss';

const PageTitle = props => {
  return (
    <h1 className={css.wrapper}>
      {props.children}
    </h1>
  );
};

export default PageTitle;
