import React, {useEffect, useState} from "react";
import css from "./appLoader.module.scss";

export const loaderPositions = {
    center: 'positionCenter',
    top: 'positionTop',
    topRight: 'positionTopRight',
    outsideRight: 'positionOutsideRight'
}

export const loaderSizes = {
    mini: 'mini',
    tiny: 'tiny',
    small: 'small',
    medium: 'medium',
    large: 'large',
    big: 'big',
    huge: 'huge',
    massive: 'massive'
};

/*
Based on Semantic Dimmer & Loader
 */
const AppLoader = props => {
    const {
        loaderActive = true,
        text = "",
        hideContent = false,
        size = loaderSizes.medium,
        position = loaderPositions.center,
        transition = true, // will be interpreted as false if hideContent=true (to prevent blinking white screen without loader)
        className = ''
    } = props;

    // const color = 'rgb(84, 148, 32)';
    const color = 'rgba(255, 255, 255, 1)';

    const loaderTransitionForFadeInClass = loaderActive ? css.loaderTransitionForFadeIn : '';

    const [loaderTransitionForFadeOutClass, setLoaderTransitionForFadeOut] = useState('');

    useEffect(() => {
        setLoaderTransitionForFadeOut(css.loaderTransitionForFadeOut);
    }, []);

    const loaderNoTransitionClass = (!transition || hideContent) ? css.loaderNoTransition : '';

    const dimmerActiveClass = loaderActive ? css.dimmerActive : '';
    const hideContentClass = hideContent ? css.hideContent : '';

    const sizeClass = css[size];
    const textClass = text ? css.text : '';
    const positionClass = css[position];

    return (
        <div className={css.appLoaderWrapper}>
            <div className={[css.appLoaderDimmer, dimmerActiveClass, hideContentClass, className].join(' ')}>
                <div className={css.dimmerContent}>
                    <div
                        className={[css.loaderWrapper, loaderTransitionForFadeOutClass, loaderTransitionForFadeInClass, loaderNoTransitionClass].join(' ')}>
                        <div className={[css.loader, textClass, sizeClass, positionClass].join(' ')}>
                            <div className={css.before} style={{borderColor: color}}/>
                            <div className={css.textWrapper}>{text}</div>
                            <div className={css.after} style={{borderColor: `${color} transparent`}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AppLoader;