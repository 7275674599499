import React from "react";
import css from './sectionTextWrapper.module.scss';

const SectionTextWrapper = props => {
    const {
        desktopWidth = '50%',
        desktopMaxTextWidth = '550px',// TODO unused?
        isBoldText = true,
    } = props;

    const isBold = isBoldText ? css.boldText : ''
    return (
        <div className={css.textWrapper} style={{width: desktopWidth}}>
            <div className={css.inner}>
                <div className={[css.text, isBold].join(' ')} style={{maxWidth: desktopMaxTextWidth}}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default SectionTextWrapper;