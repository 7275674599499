import React from "react";
import css from './sectionTitle.module.scss';

const SectionDesktopTitle = props => {
    return <div className={[css.wrapper, css.desktop].join(' ')}>{props.children}</div>;
}

const SectionMobileTitle = props => {
    return <div className={[css.wrapper, css.mobile].join(' ')}>{props.children}</div>;
}

export {SectionDesktopTitle, SectionMobileTitle};