import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './oemPartner.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
// import PhoneFrameVideo from "../../shared/PhoneFrameVideo/PhoneFrameVideo";
import Text from "../../shared/Text/Text";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import AppGreyText from "../../shared/AppGreyText/AppGreyText";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_oem_partner"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const OemPartner = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const title = 'OEM Partner';

    return (

        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'oem_partner.png', 'OEM partner')}
                    </div>
                    {/*<PhoneFrameVideo
                        videoSrcURL={MarkingVideo}
                        placeholderImage={'Marking_Video.jpg'}
                        imagesFluid={imagesFluid}
                    />*/}
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <Text>
                    <AppGreyText>How </AppGreyText>
                    about broadening your offering by adding our technology?
                    <br/><br/>
                    Upselling <AppGreyText> is </AppGreyText>vital for growing the business
                    and customers satisfaction.
                    <br/><br/>
                    Collaborations with 3d party
                    vendors <AppGreyText>is </AppGreyText>giving a direct access to
                    technologies, <AppGreyText>where </AppGreyText>workflow automation is
                    inevitable.
                </Text>
            </SectionTextWrapper>
        </div>
    )
};

export default OemPartner;
