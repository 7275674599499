import React from "react";
import css from './sectionImageVideoWrapper.module.scss';

const SectionImageVideoWrapper = props => {
    const {desktopWidth = '50%'} = props;
    return (
        <div className={css.imageVideoWrapper} style={{width: desktopWidth}}>
            <div className={css.imageVideoWrapperInner}>
                {props.children}
            </div>
        </div>
    )
}

export default SectionImageVideoWrapper;