import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './additionalInformation.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
import {useTranslation} from "react-i18next";
import CompanyContactMail from "../CompanyContactMail/CompanyContactMail";
import MainButton, {mainButtonArrow, mainButtonFill} from "../MainButton/MainButton";
import CompanySalesMail from "../CompanySalesMail/CompanySalesMail";
import CompanySupportMail from "../CompanySupportMail/CompanySupportMail";

const query = graphql`
 {
   fluid: allFile(filter: {sourceInstanceName: {eq: "images_common"}}) {
     nodes {
       childImageSharp {
         fluid(maxWidth: 1090, quality: 70) {
           ...GatsbyImageSharpFluid,
           originalName
         }
       }
     }
   }
 }`;


const AdditionalInformation = (props) => {
    const {typeMail} = props
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const companyTypeMail = () => {
        switch (typeMail) {
            case 'company':
                return (
                    <CompanyContactMail
                        contentToDisplay={<MainButton
                            label={'Contact us'}
                            arrow={mainButtonArrow.right}
                            fill={mainButtonFill.gradient}
                        />}
                    />
                )
            case 'sales':
                return (
                    <CompanySalesMail
                        contentToDisplay={<MainButton
                            label={'Contact us'}
                            arrow={mainButtonArrow.right}
                            fill={mainButtonFill.gradient}
                        />}
                    />
                )
            case 'support':
                return (
                    <CompanySupportMail
                        contentToDisplay={<MainButton
                            label={'Contact us'}
                            arrow={mainButtonArrow.right}
                            fill={mainButtonFill.gradient}
                        />}
                    />
                )
            default:
                return (
                    <CompanyContactMail
                        contentToDisplay={<MainButton
                            label={'Contact us'}
                            arrow={mainButtonArrow.right}
                            fill={mainButtonFill.gradient}
                        />}
                    />
                )
        }

    }
    return (
        <div className={css.wrapper}>
            <div className={css.fullPageWidthBg}/>
            <div className={css.backgroundImage}>
                {renderImageByFileName(imagesFluid, 'Contact_us_bg.jpg', 'Contact_us_bg.jpg')}
            </div>
            <div className={css.textWrapper}>
                <div className={css.title}>
                    <div>Need more information?</div>
                    <div className={css.text}>
                        <div>For additional information please</div>
                        <div className={css.button}>
                            {companyTypeMail()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AdditionalInformation;
