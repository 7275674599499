import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './partnershipJoin.module.scss';
import {getImagesFluid, renderImageByFileName} from "../../../../componentsUtils";
import {useTranslation} from "react-i18next";
import {getLink, getRoutes} from "../../../../shared/routes";
import {Link} from 'gatsby';
import Text from "../../../../shared/Text/Text";
import AppGreyText from "../../../../shared/AppGreyText/AppGreyText";
import MainButton, {mainButtonArrow, mainButtonFill} from "../../../../shared/MainButton/MainButton";


const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_Partnership"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 1060, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const PartnershipJoin = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    return (
        <div className={css.wrapper}>
            <div className={css.title}>
                Interested in becoming<br/>our business partner?
            </div>
            <div className={css.contentWrapper}>
                <div className={css.textWrapper}>
                    <div className={css.inner}>
                        <div className={css.text}>
                            <Text>
                                <br/>
                                <AppGreyText>Welcome to join the </AppGreyText>
                                Deeplai Partner Program,<br/>
                                <AppGreyText>please </AppGreyText>
                                contact us <AppGreyText>for more information. </AppGreyText>
                                <br/><br/>
                                <Link to={getLink([getRoutes().Contact])}>
                                    <MainButton
                                        label={'Contact us'}
                                        arrow={mainButtonArrow.right}
                                        fill={mainButtonFill.gradient}
                                    />
                                </Link>
                            </Text>
                        </div>
                    </div>
                </div>
                <div className={css.imageWrapper}>
                    {renderImageByFileName(imagesFluid, 'business_partner_hero.png', 'business_partner_hero')}
                </div>
            </div>
        </div>

    )
};

export default PartnershipJoin;
