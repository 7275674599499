import React from 'react';
import css from './mainButton.module.scss';
import ArrowLeft from '../../../images/icons/arrow_small_left.svg';
import ArrowRight from '../../../images/icons/arrow_small_right.svg';
import ArrowDown from '../../../images/icons/arrow_small_down.svg';
import AppLoader, {loaderPositions, loaderSizes} from "../AppLoader/AppLoader";

export const mainButtonFill = {
    gray: 'gray',
    gradient: 'gradient'
}

export const mainButtonArrow = {
    none: 'none',
    right: 'right',
    rightDown: 'rightDown',
    left: 'left'
}

const MainButton = props => {
    const {
        className,
        href = '',
        label = '',
        fill = mainButtonFill.gray,
        arrow = mainButtonArrow.none,
        leftIcon = null,
        tight = false,
        componentRef = null,
        onClick = () => {
        },
        loading = false,
        formikConfig,
        disabled = false
    } = props;

    const tightClass = tight ? css.tight : '';

    const isDisabled = () => {
        if (disabled) {
            return true;
        }
        if (!!formikConfig) {
            return formikConfig?.isSubmitting || !formikConfig?.isValid;
        }
        return false;
    }

    const disabledClass = isDisabled() ? css.disabled : '';

    const renderContent = () => {
        return (
            <>
                {fill === mainButtonFill.gradient && !isDisabled() && <div className={css.filledGradientBg}/>}
                <div className={css.content}>
                    {!!leftIcon &&
                        <img src={leftIcon} alt="" className={css.leftIcon}/>}
                    {arrow === mainButtonArrow.left &&
                        <img src={ArrowLeft} alt="" className={[css.arrowImg, css.arrowImgLeft].join(' ')}/>}
                    {label}
                    {arrow === mainButtonArrow.right &&
                        <img src={ArrowRight} alt="" className={[css.arrowImg, css.arrowImgRight].join(' ')}/>}
                    {arrow === mainButtonArrow.rightDown &&
                        <img src={ArrowDown} alt="" className={[css.arrowImg, css.arrowImgRightDown].join(' ')}/>}
                    <AppLoader loaderActive={loading} size={loaderSizes.small} position={loaderPositions.outsideRight}/>
                </div>
            </>
        );
    }

    if (!!href) {
        return (
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className={[css.mainButton, tightClass, className].join(' ')}
                ref={componentRef}
                onClick={onClick}
            >
                {renderContent()}
            </a>
        );
    }

    return (
        <div className={[css.mainButton, tightClass, disabledClass, className].join(' ')} ref={componentRef}
             onClick={onClick}>
            {renderContent()}
        </div>
    );

};

export default MainButton;
