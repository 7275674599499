import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './businessPartner.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
// import PhoneFrameVideo from "../../shared/PhoneFrameVideo/PhoneFrameVideo";
import Text from "../../shared/Text/Text";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import AppGreyText from "../../shared/AppGreyText/AppGreyText";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_business_partner"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const BusinessPartner = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const title = 'Business Partner';

    return (

        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'34%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'business_partner.png', 'business partner')}
                    </div>
                    {/*<PhoneFrameVideo
                        videoSrcURL={MarkingVideo}
                        placeholderImage={'Marking_Video.jpg'}
                        imagesFluid={imagesFluid}
                    />*/}
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'60%'}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <Text>
                    <AppGreyText>How</AppGreyText>
                    about marketing the edge technologies <AppGreyText>to </AppGreyText> your customers
                    and/or on your marketplace?
                    <br/><br/>
                    <AppGreyText>We</AppGreyText>
                    value experienced business partners <AppGreyText>with </AppGreyText> establish
                    market position <AppGreyText>as </AppGreyText>well as these with open mind
                    <AppGreyText> and </AppGreyText>new bright ideas,
                    <AppGreyText>just as</AppGreyText>
                    <app-trademark> Uber</app-trademark>
                    .
                </Text>
            </SectionTextWrapper>
        </div>
    )
};

export default BusinessPartner;
