import React from 'react';
import css from "../Text/text.module.scss";

const Text = (props) => {
    const {children, textIsBold = true} = props
    const styles = textIsBold ? {fontWeight: 700} : {fontWeight: 'normal'}

    return (
        <div className={css.text} style={styles}>
            {children}
        </div>
    )
}

export default Text;