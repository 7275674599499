import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import Footer from "../components/shared/Footer/Footer";
import PartnershipJoin from "../components/pages/PartnershipPage/partials/partnershipJoin/PartnershipJoin";
import BusinessPartner from "../components/sections/BusinessPartner/BusinessPartner";
import OemPartner from "../components/sections/OemPartner/OemPartner";
import SystemIntegrator from "../components/sections/SystemIntegrator/SystemIntegrator";
import AdditionalInformation
    from "../components/shared/additionalInformation/additionalInformation";

import {getRoutes} from '../components/shared/routes';
import PageTitle from "../components/shared/PageTitle/PageTitle";

const PartnershipPage = () => {
    const pageTitle = getRoutes().Partnership.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle} description={'Join the team of Business Partners'}/>

            <PageTitle>{pageTitle}</PageTitle>

            <PartnershipJoin/>

            <BusinessPartner/>

            <OemPartner/>

            <SystemIntegrator/>

            <AdditionalInformation/>

            <Footer/>
        </Layout>
    );
};

export default PartnershipPage;
