import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './systemIntegrator.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
// import PhoneFrameVideo from "../../shared/PhoneFrameVideo/PhoneFrameVideo";
import Text from "../../shared/Text/Text";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import AppGreyText from "../../shared/AppGreyText/AppGreyText";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_system_integrator"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const SystemIntegrator = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const title = 'System Integrator';

    return (

        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'44%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'system_integrator.png', 'system integrator')}
                    </div>
                    {/*<PhoneFrameVideo
                        videoSrcURL={MarkingVideo}
                        placeholderImage={'Marking_Video.jpg'}
                        imagesFluid={imagesFluid}
                    />*/}
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'50%'}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <Text>
                    <AppGreyText>How </AppGreyText>
                    about adding to your solution offering workflow automation
                    processing <AppGreyText>based on</AppGreyText> AI/ML models?
                    <br/><br/>
                    AWS infrastructure, API seamless integrations, easy to implement cloud-based
                    software <AppGreyText> are</AppGreyText> our key solutions
                    attributes.
                </Text>
            </SectionTextWrapper>
        </div>
    )
};

export default SystemIntegrator;
